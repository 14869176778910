<template>
	<div class="Index w">
		<!-- 顶部的内容 start -->
		<div class="top bgfff ptb32 mt24 br4">
			<!-- 搜索框 start -->
			<div class="search flex align-center">
				<div class="input-wrap pr00">
					<el-input
						class="input"
						v-model="keyword"
						placeholder="搜索你想要的内容"
						@change="
							jumpList(
								{
									id: 0,
									is_area: 0,
									level: 0,
								},
								keyword
							)
						"
					>
						<i class="el-icon-search el-input__icon" slot="prefix"></i
					></el-input>
					<el-button
						@click="
							jumpList(
								{
									id: 0,
									is_area: 0,
									level: 0,
								},
								keyword
							)
						"
						class="button"
						>搜索</el-button
					>
				</div>
				<router-link
					:to="{
						name: 'Integral',
						params: {
							curType: 'sign',
						},
					}"
				>
					<img
						class="sign ml32 cursor"
						src="@/assets/imgs/home-sign.png"
						alt="签到"
					/>
				</router-link>
			</div>
			<!-- 搜索框 end -->

			<!-- 热搜 start -->
			<div class="hots pr00 plr32 flex justify-center mt24">
				<div class="pr00">
					<img class="bg pa00" src="@/assets/imgs/home-hot.png" />
					<div class="text-wrap">
						<div class="flex align-center pr00">
							<span
								@click="jumpList({ id: 0, is_area: 0, level: 0 }, item.keyword)"
								v-for="(item, index) in hotKeyWords"
								:key="index"
								class="cursor"
							>
								{{ item.keyword }}
							</span>
						</div>
					</div>
				</div>
			</div>
			<!-- 热搜 end -->

			<!-- 政策分类 start -->
			<div class="policy-type" v-if="catagory.length > 0">
				<div class="flex flex-wrap">
					<div
						@click="jumpList(item)"
						v-for="(item, index) in catagory.slice(0, 20)"
						:key="index"
						class="item flex justify-center align-center mt32"
					>
						<div class="cursor flex flex-direction justify-center align-center">
							<img :src="item.thumb" />
							<span class="text-c">{{ item.name }}</span>
						</div>
					</div>
					<div
						v-if="isAll"
						@click="jumpList(catagory[20])"
						class="item flex justify-center align-center mt32"
					>
						<div class="cursor flex flex-direction justify-center align-center">
							<img :src="catagory[20].thumb" />
							<span class="text-c">{{ catagory[20].name }}</span>
						</div>
					</div>
					<div v-else class="item flex justify-center align-center mt32">
						<div
							@click="isAll = 'all'"
							class="cursor flex flex-direction justify-center align-center"
						>
							<img src="@/assets/imgs/home-more-icon.png" />
							<span class="text-c">更多</span>
						</div>
					</div>
				</div>
				<el-collapse v-model="isAll">
					<el-collapse-item name="all">
						<div class="policy-type-all flex flex-wrap" ref="policyTypeAll">
							<div
								@click="jumpList(item)"
								v-for="(item, index) in catagory.slice(21)"
								:key="index"
								class="item flex justify-center align-center mt32"
							>
								<div
									class="cursor flex flex-direction justify-center align-center"
								>
									<img :src="item.thumb" />
									<span class="text-c">{{ item.name }}</span>
								</div>
							</div>
							<div class="item flex justify-center align-center mt32">
								<div
									@click="isAll = ''"
									class="cursor flex flex-direction justify-center align-center"
								>
									<img src="@/assets/imgs/policy-type-close.png" />
									<span class="text-c">收起</span>
								</div>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
			<!-- 政策分类 end -->
		</div>
		<!-- 顶部的内容 end -->

		<!-- 广告 start -->
		<div class="banner mt24" v-if="ad.length > 0">
			<el-carousel
				class="index-carousel"
				indicator-position="outside"
				height="235px"
			>
				<!-- :autoplay="!imagD" -->
				<el-carousel-item v-for="(item, index) in ad" :key="index">
					<img :src="item.thumb" @click="externalHandle(item)" />
					<!-- :preview-src-list="imagD ? [item.thumb] : []" -->
				</el-carousel-item>
			</el-carousel>
		</div>

		<!-- 广告 end -->
		<!-- @click="externalHandle(item)" -->
		<!-- 政策列表 start -->
		<div class="policy-list-wrap mt24">
			<div class="policy-wrap flex">
				<!-- 政策 start -->
				<div v-if="articleList.length > 0" class="policy bgfff pr00 br4">
					<div class="policy-top flex justify-between align-center plr24 pt24">
						<div class="left flex align-center">
							<img src="@/assets/imgs/index-policy-top.png" />
							<span class="ml8 fs20 font-33">政策</span>
							<span class="ml12 fs16 font-99">Policy</span>
						</div>
						<div
							@click="
								jumpList({
									id: 0,
									is_area: 0,
									level: 0,
								})
							"
							class="all flex align-center fs12 font-99 cursor"
						>
							全部<i class="el-icon-arrow-right"></i>
						</div>
					</div>
					<div class="list br24 ptb24">
						<div
							@click="jumpDetail(item)"
							v-for="(item, index) in articleList.slice(0, 4)"
							:key="index"
							class="item flex align-center cursor plr24"
						>
							<div class="left">
								<span>{{ item.cate_name }}</span>
							</div>
							<span class="line1 ml12">{{ item.title }}</span>
						</div>
					</div>
				</div>
				<!-- 政策 end -->

				<div class="policy-right">
					<div
						@click="jumpDetail(item)"
						v-for="(item, index) in arrs.slice(0, 2)"
						:key="index"
					>
						<ItemCon
							class="bgfff br4 plr24 pb24 global-hover"
							:isTit="false"
							:item="item"
							:style="{ width: '100%' }"
						></ItemCon>
					</div>
				</div>
			</div>

			<div class="policy-list flex">
				<div
					v-for="(item, index) in arrs.slice(2)"
					:key="index"
					@click="jumpDetail(item)"
				>
					<ItemCon
						class="bgfff br4 plr24 pb24 global-hover"
						:isTit="false"
						:item="item"
						:style="{ width: '100%' }"
					></ItemCon>
				</div>
			</div>
		</div>
		<!-- 政策列表 end -->

		<!-- 是否可以查看弹窗 -->
		<AlonePermission
			ref="myAlonePermission"
			:detail="curDetail"
			:detailInfo="curDetailInfo"
			@confirm="jumpConfirm"
			@cancel="jumpCancel"
		></AlonePermission>

		<!-- 积分弹窗 -->
		<el-dialog
			class="integral-popup-wrap"
			center
			:visible.sync="integralVisible"
			width="420px"
			style="border-radius: 24px"
		>
			<div class="integral-popup popup bgfff br24 pr00">
				<img
					class="pa00"
					style="width: 100%"
					mode="widthFix"
					src="@/assets/imgs/home-popup-bg.png"
				/>

				<div class="title pr00 flex align-center justify-center ptb32">
					<img
						class="close position-absolute"
						@click="integralVisible = false"
						src="@/assets/public/close-popup.png"
					/>
					<img class="popup-left mr24" src="@/assets/imgs/popup-left.png" />
					积分
					<img class="popup-right ml24" src="@/assets/imgs/popup-right.png" />
				</div>

				<div class="con pr00">
					新用户平台已赠送您{{
						score
					}}积分，到积分钱包；凭积分可以免费查看下载您所需要的政策；
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import ItemCon from "@/components/ItemCon.vue";
import AlonePermission from "@/components/AlonePermission.vue";

export default {
	name: "Index",
	components: {
		ItemCon,
		AlonePermission,
	},
	data() {
		return {
			imagD: false, //是否可以查看更多
			isAll: "", // 是否查看更多中
			score: "", // 奖励的积分  积分弹窗中使用到了
			hotKeyWords: [], // 热搜
			catagory: [], // 金刚区
			ad: [], // 广告内容
			articleList: [], // 政策数据

			curDetail: {}, // 当前要进入的详情   与 alone-permission 组件配合使用
			curDetailInfo: {}, // 当前要进入的详情 的信息
			jumpLoading: false, // 是否在请求 跳转详情的接口

			arrs: [],
			total: 0, // 总条数
			pageSize: 20, // 每页条数
			page: 1, // 当前的页码
			listLoading: false,

			keyword: "", // 搜索关键字

			integralVisible: false, // 积分的弹窗

			isCurrentView: false, // 当前是否在当前页面
			scorllTop: 0, // 距离顶部得距离
			urlTap: "",
		};
	},
	watch: {
		"$store.getters.token": {
			handler(newVal) {
				//	console.log(newVal);
				if (newVal.length > 3) {
					this.isFirstLogin();
				}
			},
			immediate: true,
			deep: true,
		},
	},
	created() {
		this.getHomeData();
		this.getList();
	},

	mounted() {
		window.addEventListener("scroll", this.indexScroll);
	},
	// keep-alive 活跃的
	activated() {
		this.isCurrentView = true;

		if (this.scorllTop > 0) {
			document.documentElement.scrollTop = this.scorllTop;
			document.body.scrollTop = this.scorllTop;
		}
	},
	// keep-alive 失去活跃
	deactivated() {
		this.isCurrentView = false;
	},
	destroyed() {
		window.removeEventListener("scroll", this.indexScroll);
	},
	methods: {
		//跳转链接
		externalHandle(e) {
			this.imagD = false;
			//	/pages/list/type/index?id=48&is_area=1

			let indexs = e.href.indexOf("vip");
			//console.log(indexs);
			if (indexs < 0) {
				let a = e.href.indexOf("?");
				this.urlTap = e.href.substring(a);
				let id = this.getUrlParms("id");
				let is_area = this.getUrlParms("is_area");
				let level = this.getUrlParms("level");
				if (!id) {
					this.imagD = true;
					return false;
				}

				this.$router.push({
					name: "Lists",
					params: {
						id,
						is_area,
						level,
					},
				});
			} else {
				this.$router.push({
					name: "Vip",
				});
			}

			console.log(this.urlTap);
			console.log(this.urlTap);

			//	console.log(id);
		},

		//	解析url
		getUrlParms(name) {
			let url = this.urlTap;
			let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
			let r = url.substr(1).match(reg);
			if (r != null) return unescape(r[2]);
			return null;
		},
		indexScroll() {
			if (this.isCurrentView) {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				this.scorllTop = scrollTop;
			}
		},
		// 支付取消
		jumpCancel() {
			// this.curDetail = {};
			// this.curDetailInfo = {};
		},
		// 支付成功 进入详情
		jumpConfirm() {
			this.$router.push({
				name: "Detail",
				query: {
					id: this.curDetail.id,
				},
			});
		},
		// 跳转详情页
		async jumpDetail(item) {
			// 调用接口 判断是否可以直接进入
			//  不行    弹窗 去支付
			//  可以    就直接进入

			if (this.jumpLoading) {
				this.$message.info("您点击的太快了");
				return false;
			}

			this.jumpLoading = true;
			this.curDetail = { ...item };
			let res = await this.$isEnterDetailFun(
				{
					id: item.id,
				},
				true
			);

			this.jumpLoading = false;
			if (res == "catch") {
				return false;
			}

			this.curDetailInfo = res;

			if (res.is_pay == 1) {
				this.popupFun(true, "myAlonePermission");
				return false;
			}

			this.jumpConfirm();
		},
		// 跳转金刚区列表页
		jumpList(item, keyword = "") {
			let { id, is_area, level } = item;
			let query = keyword ? { keyword } : {};
			//	console.log(id);
			this.$router.push({
				name: "Lists",
				params: {
					id,
					is_area,
					level,
				},
				query,
			});
		},
		// 获取列表
		getList() {
			let { pageSize, page } = this;

			this.listLoading = true;
			this.$pageScrollTop();

			this.$API
				.homeList({
					page,
					pagesize: pageSize,
					list_rows: pageSize,
					pageSize,
				})
				.then((res) => {
					this.listLoading = false;

					if (res.data.last_page == page || res.data.last_page == 0)
						this.lastPage = true;

					if (res.code == 1) {
						if (this.page == 1) {
							this.arrs = res.data.data;
						} else {
							this.arrs = this.arrs.concat(res.data.data);
						}
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 获取数据集合
		getHomeData() {
			this.$API.homeData().then((res) => {
				if (res.code == 1) {
					let { ad, articleList, catagory, hotKeyWords } = res.data;

					this.catagory = [...catagory];
					this.hotKeyWords = [...hotKeyWords];
					this.ad = [...ad];
					this.articleList = [...articleList];
				}
			});
		},
		// 是否首次登录
		isFirstLogin() {
			this.$API.isFirstLogin().then((res) => {
				if (res.code == 1) {
					let { is_notice, score } = res.data;
					// is_notice  是否弹框提醒 0不提醒 1提醒
					// score 奖励积分
					this.score = score;

					if (is_notice == 1) {
						this.integralVisible = true;
					}
				}
			});
		},
		/**
		 * ! 是否显示弹窗
		 * @param {sign} sign 显示隐藏 true 显示 false 隐藏
		 * @param {ref} ref 那个类型
		 */
		popupFun(sign, ref) {
			if (sign) {
				try {
					this.$refs[ref].open();
				} catch (error) {
					this.$refs[ref].show();
				}
			} else {
				try {
					this.$refs[ref].close();
				} catch (error) {
					this.$refs[ref].hide();
				}
			}
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.banner {
		.index-carousel {
			position: relative;
			left: 0;
			top: 0;

			.el-carousel__container {
				border-radius: 12px;
				overflow: hidden;
			}

			img {
				width: 100%;
				height: 100%;
			}

			.el-carousel__indicators--outside {
				position: absolute;
				bottom: 8px;
				left: 50%;
				transform: translateX(-50%);
				z-index: 10;

				.el-carousel__button {
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background-color: #ffffff;
					opacity: 1;
				}

				.is-active {
					.el-carousel__button {
						width: 24px;
						height: 8px;
						background: #ff8300;
						opacity: 1;
						border-radius: 6px;
					}
				}
			}
		}
	}

	.search {
		input {
			height: 46px;
			border-radius: 100px;
			border-color: #ff8300;
			border-width: 2px;
			padding-left: 48px;

			&:focus {
				border-color: #ff8300;
			}
		}

		.el-input__prefix {
			padding-left: 12px;
			font-size: 18px;
			display: flex;
			align-items: center;
			justify-content: center;

			i {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.el-collapse {
		border: none;
	}

	.el-collapse-item__header {
		border: none;
		display: none;
	}

	.el-collapse-item__wrap {
		border: none;
	}

	.el-collapse-item__arrow {
		display: none;
	}

	.integral-popup-wrap {
		.el-dialog {
			border-radius: 24px;
		}
	}
}

.Index {
	@extend .h100;
	padding-bottom: 64px;

	.top {
		.search {
			padding: 0 32px;

			.input-wrap {
				flex: 1;
				overflow: hidden;

				.button {
					padding: 0;
					margin: 0;
					border: none;
					border-radius: 100px;
					position: absolute;
					right: 4px;
					top: 50%;
					transform: translateY(-50%);
					width: 88px;
					height: 40px;
					background: #ff8300;
					font-size: 16px;
					font-weight: 400;
					color: #ffffff;
				}
			}

			.sign {
				width: 36px;
				height: 38px;
			}
		}

		.hots {
			z-index: 12;

			> div {
				min-height: 32px;
				background-color: #f6f7f9;
				border-radius: 12px;

				.bg {
					height: 100%;
				}

				.text-wrap {
					padding-left: 110px;
					padding-right: 32px;
					width: 100%;
					height: 100%;
					overflow: hidden;

					div {
						flex-wrap: wrap;
						height: 100%;
						height: 100%;
						max-width: 800px;

						span {
							margin: 0 16px;
							color: #333333;

							&:first-child {
								margin-left: 0;
							}

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}

		.policy-type {
			z-index: 12;
			overflow: hidden;

			> div {
				align-items: baseline;
				width: 1232px;
				margin-left: -16px;

				.item {
					width: 176px;

					img {
						width: 64px;
						height: 64px;
						margin-bottom: 8px;
					}
				}
			}

			// .policy-type-all {
			// transition: height 0.2s;
			// }
		}
	}

	.policy-list-wrap {
		.policy-wrap {
			.policy {
				flex: 1;
				margin-right: 4px;
				overflow: hidden;

				.policy-top {
					.left {
						img {
							width: 42px;
							height: 30px;
						}
					}

					.all {
						i {
							margin-top: 1px;
						}
					}
				}

				.list {
					.item {
						margin-top: 24px;

						&:first-child {
							margin-top: 0;
						}

						.left {
							background: linear-gradient(to right, #ff932b, #f53731);
							border-radius: 6px;
							transform: skewX(-12deg);
							padding: 4px 0;

							span {
								color: #ffffff;
								font-size: 14px;
								padding: 0 12px;
							}
						}

						.line1 {
							flex: 1;
							font-size: 16px;
							color: #333333;
						}
					}
				}
			}

			.policy-right {
				flex: 1;
				margin-left: 4px;
				overflow: hidden;

				> div {
					overflow: hidden;
					margin-bottom: 16px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		.policy-list {
			flex-wrap: wrap;

			> div {
				width: 50%;
				margin-top: 16px;
				overflow: hidden;

				&:nth-child(odd) {
					padding-right: 4px;
				}

				&:nth-child(even) {
					padding-left: 4px;
				}
			}
		}
	}

	.popup {
		overflow: hidden;

		.title {
			font-size: 22px;
			font-weight: 600;
			color: #333333;

			img {
				width: 28px;
				height: 10px;
			}

			.close {
				right: 32px;
				top: 24px;
				width: 18px;
				height: 18px;
				cursor: pointer;
			}
		}
	}

	.integral-popup {
		width: 420px;

		.title {
			padding-top: 50px;
		}

		.con {
			padding: 40px 28px;
			font-size: 14px;
			font-weight: 400;
			color: #333333;
		}
	}
}
</style>
